<!--用户管理-->
<template>
    <div class="mainBox">
        <!--场景搜索-->
        <div class="selectionBar">
            <div class="flex flex-ai-c flex-jc-sb  flex-wrap">
                <label>场景：</label>
                <el-input placeholder="姓名/手机号/昵称" v-model="select.keyword" size="small" style="width:350px;" @change="curr=1;getList()">
                    <el-button slot="append" icon="el-icon-search" style="background：#fff"></el-button>
                </el-input>
				<el-button type="success" block size="mini">非会员：{{count}}个</el-button>
            </div>
        </div>

        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 175px )">
            <el-table :data="list"  stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
				<el-table-column prop="id" label="编号" show-overflow-tooltip>
				</el-table-column>
                <el-table-column prop="realname" label="姓名" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="garage_name" label="公司名称：" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="mobile" width="100" label="手机号" show-overflow-tooltip>
                </el-table-column>
<!-- 				<el-table-column prop="nickname" label="昵称" show-overflow-tooltip>
                </el-table-column> -->
                <el-table-column label="头像" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.headimgurl" :src ="scope.row.headimgurl" class="tableImage" :preview-src-list="[scope.row.headimgurl]"></el-image>
                        <el-avatar v-else shape="square" :size="45" :src="require('../../assets/ava.png')"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="order_count" align="center" label="订单数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="order_amount_payable_sum" align="center" label="订单总金额" show-overflow-tooltip></el-table-column>
                <el-table-column prop="recharge_count" align="center" label="充值次数" show-overflow-tooltip></el-table-column>
                <el-table-column prop="recharge" align="center" label="充值余额" show-overflow-tooltip></el-table-column>
                <!-- <el-table-column prop="son_count" align="center" label="推广次数" show-overflow-tooltip></el-table-column> -->
                <!-- <el-table-column prop="balance" align="center" label="账户余额" show-overflow-tooltip></el-table-column> -->
                <!-- <el-table-column prop="balance_sum_use" align="center" label="余额使用" show-overflow-tooltip></el-table-column> -->
                <el-table-column prop="create_time" align="center" label="注册时间" width="160" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="100">
                    <template slot-scope="scope">
                        <el-button type="primary" plain size="mini" @click="showDetail(scope.row,'show')">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    import {memberListApi}  from '@/api/member.js';
    export default {
        data() {
            return {
                formData:{}, //详情数据
                select:{
                    member_id:0
                },

                list:[], //会员信息列表
                selectData:[{
                    id:0,
                    name:'普通用户'
                },{
                    id:1,
                    name:'会员用户'
                }],
                curr: 1, //页码
                row: 10, //每页条数
                pages: 1, //总页数
                count: 0, //总条数
            }
        },
        mounted() {
			this.select.warehouse_id = this.$route.query.id ? Number(this.$route.query.id) : '';
            this.getList();
        },
        methods: {
            //商品列表
            getList: function(){
				let param = this.$route.query.date ? {
					date: this.$route.query.date,
					type: this.$route.query.type,
					curr: this.curr,
					row: this.row,
					member_id: 0,
					warehouse_id:this.select.warehouse_id
				} : {
					curr: this.curr,
					row: this.row,
					...this.select
				}

                memberListApi(param).then(response=>{
                    this.list = response.list;
                    this.curr = response.curr;
                    this.pages = response.pages;
                    this.count = response.count;
                })
				
            },
            
            showDetail: function(row){
                this.$router.push({
                    path:'/memberDet',
                    query:{
                        id:row.id
                    }
                })
            },
        
            //翻页
            currentChange: function(curr) {
                this.curr = curr;
                this.getList();
            },
            //改变每页条数
            sizeChange: function(row) {
                this.row = row;
                this.getList();
            },
        },
    }
</script>